@use '@angular/material' as mat;

@import '../src/assets/styles/_mixins.sass';
@import '../src/assets/styles/_variables.sass';
@import '../node_modules/@tdoe/design-system/assets/theme/variables';
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

tdoe-layout {
    /* this makes the footer stay at the bottom of the browser window when the content 
   is shorter and stays at the bottom of the content when it’s taller */
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    .tdoe-body {
        flex: 1;
    }
}

.mat-typography h1 {
    margin-bottom: 0;
}

// reduce the size of the sword logo
.mat-toolbar {
    background-color: $c-neutral-light !important;
    img.app { 
        height: 20px !important;
    }
}

.tdoe-wrap {
    margin: 0 !important;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    &.table > .container {
        max-width: 90%;
        .sword-table {
            overflow-x: hidden;
            width: 100%;
        }
    }

    &.breadcrumb {
        background-color: $c-neutral-light;
        padding-bottom: 0 !important;
        padding-top: 0 !important;
        > .container {
            justify-content: flex-start;
            align-content: flex-end;
            width: 75em;
            h1 {
                margin: 0;
            }
        }
    }

    &.error-log > .container {
        flex-direction: column;
        align-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
    }
    
    > .container {
        width: 100%;
        max-width: 75em;
        @include mobile {
            padding: .2em;
        }
    }

    &.header-title {
        background-color: $c-neutral-light;
        > .container {
            padding-top: 0;
            padding-bottom: 0;
            justify-content: unset;
            align-items: flex-start;
        }
    }
}

.mat-mdc-tab .mdc-tab-indicator__content--underline {
    border-color: $yellow !important;
}