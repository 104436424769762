@mixin mobile
    @media (max-width: 500px)
        @content
    
@mixin tablet
    @media (min-width: 501px) and (max-width: 1200px)
        @content

@mixin smallAndMediumScreens
    @media (max-width: 900px)
        @content

@mixin mobileAndTablet
    @media ( max-width:  1200px)
        @content

@mixin largeScreens
    @media (min-width: 1480px)
        @content

@mixin veryLargeScreens
    @media (min-width: 1700px)
        @content

@mixin extraLargeScreens
    @media (min-width: 1800px)
        @content